var render = function render(){var _vm=this,_c=_vm._self._c;return _c('nerve-dialog',{attrs:{"scrollable":"","width":"600"},model:{value:(_vm.dialogState),callback:function ($$v) {_vm.dialogState=$$v},expression:"dialogState"}},[_c('v-card',{staticClass:"update-wl"},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('updateDeployedWorkload.availableVersions'))+" ")]),_c('v-card-text',[_c('v-container',{staticClass:"pt-0 text-left"},[_c('v-simple-table',{attrs:{"id":"iiotUpdateDeployedWorkloadDialogSimpleTable","fixed-header":"","height":_vm.getVersions.length >= 4 ? '30vh' : _vm.getVersions.length === 3 ? '20vh' : ''},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v(" "+_vm._s(_vm.$t('updateDeployedWorkload.name'))+" ")]),_c('th',{staticClass:"text-left"},[_vm._v(" "+_vm._s(_vm.$t('updateDeployedWorkload.releaseName'))+" ")])])]),_c('tbody',_vm._l((_vm.getVersions),function(item){return _c('tr',{key:item.name,staticClass:"pointer",class:{ primary: item.id === _vm.selectedVersion.id },on:{"click":function($event){return _vm.selectVersion(item)}}},[_c('td',{class:{
                    'version-class': item.name === _vm.version,
                    primary: item.id === _vm.selectedVersion.id,
                  }},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('td',{class:{
                    'version-class': item.name === _vm.version,
                    primary: item.id === _vm.selectedVersion.id,
                  }},[_vm._v(" "+_vm._s(item.releaseName)+" ")])])}),0)]},proxy:true}]),model:{value:(_vm.selectedVersion),callback:function ($$v) {_vm.selectedVersion=$$v},expression:"selectedVersion"}})],1)],1),_c('v-card-actions',[_c('nerve-button',{attrs:{"id":"iiotUpdateDeployedWorkloadDialogCancel","text":_vm.$t('updateDeployedWorkload.cancel'),"type-of-btn":"cancel","size":"normal"},on:{"click-event":function($event){return _vm.close()}}}),_c('nerve-button',{staticClass:"ml-3",attrs:{"id":"iiotUpdateDeployedWorkloadDialogUpdate","text":_vm.$t('updateDeployedWorkload.update'),"disabled":!_vm.selectedVersion.id,"type-of-btn":"action","size":"normal"},on:{"click-event":function($event){return _vm.update()}}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }