<!--
*  TTTech nerve-management-system
*  Copyright(c) 2022. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
* -->
<template>
  <nerve-dialog
    id="iiotDialogForEstablishConnection"
    v-model="isOpenConnectingDialog"
    persistent
    scrollable
    max-width="600"
    :retain-focus="false"
  >
    <v-card>
      <v-card-title class="text-center">
        {{ $t('remoteConnection.connectionTo', { rc: name }) }}
      </v-card-title>
      <v-card-text>
        <div v-if="rc.type === 'SCREEN'">
          <v-col v-if="!link" class="pb-0">
            <v-col cols="6" offset="3">
              <v-progress-circular :size="50" color="primary" indeterminate />
            </v-col>
            <v-col v-if="isApprovalNeeded" cols="6" offset="3" class="pl-10">
              <span>{{ $t('remoteConnection.waitingForApproval') }}</span>
            </v-col>
            <v-col v-if="!isApprovalNeeded" cols="6" offset="3" class="waiting-position">
              <span>{{ $t('remoteConnection.waiting') }}</span>
            </v-col>
          </v-col>
          <v-col cols="12">
            <span v-if="link">
              {{ $t('remoteConnection.approvalLinkText') }}
              <a :href="link" target="_blank">{{ $t('remoteConnection.approvalLinkHref') }}</a>
            </span>
            <br />
            <span>{{ $t('remoteConnection.connectingNote') }}</span>
          </v-col>
        </div>
        <div v-if="rc.type === 'TUNNEL'">
          <v-col v-if="!connected" class="pb-0">
            <v-col cols="6" offset="3">
              <v-progress-circular :size="50" color="primary" indeterminate />
            </v-col>
            <v-col v-if="!connected && isApprovalNeeded" cols="6" offset="3" class="pl-10">
              <span>{{ $t('remoteConnection.waitingForApproval') }}</span>
            </v-col>
            <v-col v-if="!connected && !isApprovalNeeded" cols="6" offset="3" class="waiting-position">
              <span>{{ $t('remoteConnection.waiting') }}</span>
            </v-col>
          </v-col>
          <v-col v-if="connected" cols="12">
            <span>
              {{ $t('remoteConnection.remoteConnectionDialogUrlLink') }}
              <a id="iiotDialogForEstablishConnectionLink" :href="url" target="_blank">
                {{ $t('remoteConnection.approvalLinkHref') }}
              </a>
              <img
                id="iiotDialogForEstablishConnectionCopyLink"
                class="copyLink"
                src="/img/copy_link.svg"
                :title="$t('remoteConnection.copyLink')"
                @click="copyLinkToClipboard()"
              />
            </span>
          </v-col>
        </div>
      </v-card-text>
      <v-card-actions>
        <nerve-button
          v-if="connected"
          id="iiotDialogForEstablishConnectionButtonCloseDialog"
          :text="$t('remoteConnection.closeDialog')"
          type-of-btn="cancel"
          size="normal"
          @click-event="cancel()"
        />
        <nerve-button
          v-if="!connected"
          id="iiotDialogForEstablishConnectionButtonClose"
          :text="connected || link ? $t('remoteConnection.closeDialog') : $t('baseForm.cancelBtn')"
          type-of-btn="cancel"
          size="normal"
          @click-event="cancel()"
        />
      </v-card-actions>
    </v-card>
  </nerve-dialog>
</template>

<script>
import { NerveButton, NerveDialog } from 'nerve-ui-components';
import mqtt from '@/plugins/mqtt';
import { RC_APPROVAL_ON_NODE } from '@/constants';

export default {
  components: { NerveButton, NerveDialog },
  props: {
    name: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      link: '',
      url: '',
    };
  },
  computed: {
    isOpenConnectingDialog() {
      return this.$store.getters['remote-connection/isOpenConnectingDialog'];
    },
    connected() {
      return this.$store.getters['remote-connection/connected'];
    },
    activeConnRequestUid() {
      return this.$store.getters['remote-connection/activeConnRequestUid'];
    },
    connectionType() {
      return this.$store.getters['remote-connection/connectionType'];
    },
    rc() {
      return this.$store.getters['remote-connection/rc'];
    },
    isApprovalNeeded() {
      const selectedNode = this.$store.getters['node-tree/getSelectedNode'];
      if (selectedNode.rcApproval === RC_APPROVAL_ON_NODE.AUTO_APPROVE) {
        return false;
      }
      return selectedNode.rcApproval === RC_APPROVAL_ON_NODE.LOCAL_APPROVAL || this.rc.acknowledgment === 'Yes';
    },
  },
  watch: {
    connectionType(val) {
      if (!val || !val.type) {
        return;
      }
      if (val.type === 'SCREEN') {
        this.openGuacamoleTab(val);
        return;
      }
      this.generateTunnelUrl(val);
    },
    async isOpenConnectingDialog(newValue) {
      if (!newValue) {
        this.link = '';

        // nullify 'connected' value
        this.$store.dispatch('remote-connection/set_connected', false);
        await mqtt.unsubscribeFrom('initRc');
      }
    },
  },
  methods: {
    async cancel() {
      this.$store.dispatch('remote-connection/openCloseConnectingDialog', false);
      if (this.link) {
        return;
      }
      if (!this.connected) {
        await this.$store.dispatch('remote-connection/cancelRemoteConnApproval', this.activeConnRequestUid);
      }
    },
    async openGuacamoleTab(data) {
      const routeData = await this.$router.resolve({
        name: 'Guacamole Client',
        params: { authToken: data.authToken, identifier: data.identifier },
      });
      this.link = routeData.href;
      window.open(this.link, '_blank');
    },
    generateTunnelUrl(data) {
      const { sessionId } = JSON.parse(localStorage.getItem('session'));
      this.url = `${data.url}/${sessionId}`;
    },
    copyLinkToClipboard() {
      navigator.clipboard.writeText(this.url);
    },
  },
};
</script>
<style scoped>
.waiting-position {
  justify-content: center;
}
.copyLink {
  height: 25px;
  width: 25px;
  margin-left: 10px;
  cursor: pointer;
}
</style>
